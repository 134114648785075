import React, { useEffect, useRef, useState } from 'react'
import { CSSTransition } from 'react-transition-group';

export default ({ title, images, controls = false, auto = true }) => {
  if(!images || images.length === 0) return null;

  const [ imageIndex, setImageIndex ] = useState(0);
  const imageIndexRef = useRef(imageIndex);
  imageIndexRef.current = imageIndex;

  const timeout = 3000;
  const goToImage = (newIndex) => {
    if (newIndex < 0) {
      newIndex = images.length - 1;
    }
    if (newIndex >= images.length) {
      newIndex = 0;
    }
    setImageIndex(newIndex);
  }
  const goToPrevImage = () => {
    goToImage(imageIndexRef.current - 1);
  }
  const goToNextImage = () => {
    goToImage(imageIndexRef.current + 1);
  }
  const queueNextImage = (delay) => {
    setTimeout(() => {
      goToNextImage();
      queueNextImage(timeout);
    }, delay);
  }

  useEffect(() => {
    if (auto) {
      queueNextImage(0);
    } else {
      goToNextImage();
    }
  }, []);

  return (
    <div className="carousel">
      <h3>{title}</h3>
      {images.map((image, index) => (
        <CSSTransition in={index === imageIndex} timeout={timeout} classNames="fade">
          <img src={image.url} alt={image.alt} />
        </CSSTransition>
      ))}
      {controls && <a className="prev" onClick={() => goToPrevImage()}>&lt;</a>}
      {controls && <a className="next" onClick={() => goToNextImage()}>&gt;</a>}
    </div>
  )
}
